<template>
    <v-timeline class="pt-0" dense>
        <v-timeline-item v-if="selectedSale.step > 1" class="mb-0 pb-2 pt-5" color="app-green" small fill-dot icon="mdi-check">
            <h4 class="font-weight-light green--text">
                {{ getSaleStep(selectedSale.step - 1, 'label') }}
            </h4>

            <span class="grey--text font-italic">
                Passée
            </span>
        </v-timeline-item>

        <v-timeline-item class="mb-0 pb-2" color="app-blue" fill-dot icon="mdi-timer-sand">
            <h4 class="font-weight-light indigo--text">
                {{ selectedSale.stepLabel }}
            </h4>

            <template v-if="selectedSale.stepLabel === 'Appels de fonds' && getSalePriceTTC(selectedSale) - getSaletotalPaid(selectedSale) === 0">
                Terminée
            </template>
            <template v-else>
                En cours
            </template>

        </v-timeline-item>

        <v-timeline-item v-if="selectedSale.step < 6" class="mb-0 pb-5" color="grey" small fill-dot>
            <h4 class="font-weight-light grey--text">
                {{ getSaleStep(selectedSale.step + 1, 'label') }}
            </h4>

            <span class="grey--text font-italic">
                À venir
            </span>
        </v-timeline-item>
    </v-timeline>
</template>

<script>
import salesMixin from '../../mixins/sales';

export default {
    name: 'StepsTimeline',

    mixins: [salesMixin]
};
</script>