<template>
    <v-container>
        <h3 class="grey--text text--darken-2"> Bienvenue dans votre espace client </h3>
        <p class="subtitle-1 grey--text text--darken-2">
            <template v-if="$store.state.application.sales.length > 1">
                <template v-if="areSalesFromSameProgram">
                    Vous avez fait l'acquisition de plusieurs biens dans notre programme {{ selectedSale.program.title }} à {{ selectedSale.program.city }} et nous vous remercions encore de votre confiance. Cet espace vous est réservé.
                </template>
                <template v-else>
                    Vous avez fait l'acquisition de plusieurs biens dans nos différents programmes et nous vous remercions encore de votre confiance. Cet espace vous est réservé.
                </template>
            </template>
            <template v-else>
                Vous avez fait l'acquisition d'un bien dans notre programme <v-chip color="app-green" class="ma-0 pa-1" small label dark>{{ selectedSale.program.title }}</v-chip> à {{ selectedSale.program.city }} et nous vous remercions encore de votre confiance. Cet espace vous est réservé.
            </template>
        </p>

        <v-row>
            <v-col cols="12" lg="5">
                <ProgramSummary @reload="reload()" />
            </v-col>

            <v-col cols="12" lg="7">
                <v-row>
                    <v-col cols="12" lg="6">
                        <v-row>
                            <v-col cols="12">
                                <v-card class="d-flex flex-column justify-space-between fill-height pa-2" elevation="8">
                                    <v-card-title class="subtitle-1 pa-0">
                                        <v-icon color="app-blue" class="mr-2"> mdi-calendar-multiple </v-icon>
                                        <span class="grey--text text--darken-2"> Prochaine étape </span>
                                    </v-card-title>

                                    <v-card-text class="py-0">
                                        <StepsTimeline />
                                    </v-card-text>

                                    <v-card-actions>
                                        <v-spacer />
                                        <router-link class="caption no-text-decoration primary--text" to="/etapes"> Voir toutes les étapes </router-link>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="12">
                                <v-card class="d-flex flex-column fill-height pa-2" elevation="8">
                                    <v-card-title class="subtitle-1 pa-0 mb-2">
                                        <v-icon color="app-blue" class="mr-2"> mdi-cash-multiple </v-icon>
                                        <span class="grey--text text--darken-2"> {{ getConfig('call_for_funds.labels.app_bar_title') }} </span>

                                        <v-spacer />

                                        <number :to="getSalePriceTTC(selectedSale)" :format="$options.filters.toCurrencyString" :duration="1.5" easing="Power4.easeOut" />
                                    </v-card-title>

                                    <v-card-text class="pb-0">
                                        <v-row>
                                            <v-col class="text-center">
                                                <v-progress-circular rotate="-90" :size="100" :width="15" :value="displayPercents ? totalCalledPercent : 0" color="app-green">
                                                    <number :to="$options.filters.round(totalCalledPercent)" :format="$options.filters.round" :duration="1.5" easing="Power4.easeOut" /> %
                                                </v-progress-circular>
                                                <br>

                                                <span class="caption"> Montant appelé </span>
                                                <br>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <span class="font-weight-bold" v-on="on">
                                                            <number :to="getSaleTotalCalled(selectedSale)" :format="$options.filters.toCurrencyString" :duration="1.5" easing="Power4.easeOut" />
                                                        </span>
                                                    </template>
                                                    Sur un total de {{ getSalePriceTTC(selectedSale) | toCurrencyString() }}
                                                </v-tooltip>
                                            </v-col>

                                            <v-col class="text-center">
                                                <v-progress-circular rotate="-90" :size="100" :width="15" :value="displayPercents ? totalPaidPercent : 0" color="app-red">
                                                    <number :to="$options.filters.round(totalPaidPercent)" :format="$options.filters.floor" :duration="1.5" easing="Power4.easeOut" /> %
                                                </v-progress-circular>
                                                <br>

                                                <span class="caption"> Montant appelé réglé </span>
                                                <br>

                                                <v-tooltip top>
                                                    <template v-slot:activator="{ on }">
                                                        <span class="font-weight-bold" v-on="on">
                                                            <number :to="totalPaid" :format="$options.filters.toCurrencyString" :duration="1.5" easing="Power4.easeOut" />
                                                        </span>
                                                    </template>
                                                    <template v-if="getSaleTotalCalled(selectedSale) === 0">
                                                        Dépôt de garantie
                                                    </template>
                                                    <template v-else>
                                                        Sur un total de {{ getSaleTotalCalled(selectedSale) | toCurrencyString() }}
                                                    </template>
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>

                                        <v-row class="my-0">
                                            <v-col class="py-1 text-center">
                                                <v-tooltip v-if="getSaleLastCallForFunds(selectedSale)" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip color="app-green" label dark small v-on="on">
                                                            {{ getSaleLastCallForFunds(selectedSale).fundcall.dateSent | toDate() }}
                                                            -
                                                            <span class="font-weight-bold ml-1">
                                                                {{ getSaleLastCallForFunds(selectedSale).fundcall.amount | toCurrencyString() }}
                                                            </span>
                                                        </v-chip> <br>
                                                    </template>
                                                    Dernier appel - {{ getSaleLastCallForFunds(selectedSale).step }}
                                                </v-tooltip>
                                            </v-col>

                                            <v-col class="py-1 text-center">
                                                <v-tooltip v-if="getSaleLastPayment(selectedSale)" top>
                                                    <template v-slot:activator="{ on }">
                                                        <v-chip color="app-red" label dark small v-on="on">
                                                            {{ getSaleLastPayment(selectedSale).date | toDate() }}
                                                            -
                                                            <span class="font-weight-bold ml-1">
                                                                {{ getSaleLastPayment(selectedSale).amount | toCurrencyString() }}
                                                            </span>
                                                        </v-chip> <br>
                                                    </template>
                                                    Dernier règlement
                                                </v-tooltip>
                                            </v-col>
                                        </v-row>
                                    </v-card-text>

                                    <v-card-actions class="align-end flex-grow-1">
                                        <v-spacer />

                                        <router-link class="caption no-text-decoration primary--text" :to="getConfig('call_for_funds.labels.url')"> {{ getConfig('call_for_funds.labels.dashboard_see_more') }} </router-link>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col v-if="selectedSale.realiz3DEnabled" cols="12">
                                <a :href="realiz3DUrl" class="no-text-decoration" target="_blank">
                                    <v-card class="pa-2" elevation="8">
                                        <div class="d-flex align-center">
                                            <v-img class="flex-grow-0 mr-5" src="@/assets/realiz_3d/image_link.svg" width="50" height="50" />
                                            <span class="title text-h5 grey--text text--darken-2"> Accéder à votre configurateur </span>
                                        </div>
                                    </v-card>
                                </a>
                            </v-col>

                            <v-col v-if="selectedSale.program.hasSuiviReserves && selectedSale.deliverydate" cols="12">
                                <a href="https://client.kaliti.fr/login" class="no-text-decoration" target="_blank">
                                    <v-card class="pa-2" elevation="8">
                                        <div class="d-flex align-center">
                                            <v-img class="flex-grow-0 mr-9" src="@/assets/kaliti/logo.png" width="35" height="50" />
                                            <div>
                                                <p class="title text-h5 mb-2 grey--text text--darken-2" style="line-height: 1;"> 
                                                    Accéder au suivi des réserves

                                                    <v-chip class="float-right" color="red" dark label small href="/kaliti_guide.pdf" target="_blank">
                                                        <v-icon x-small left> fas fa-paperclip </v-icon>
                                                        1
                                                    </v-chip>
                                                </p> 
                                                <p class="mb-0 caption" style="line-height: 1;">
                                                    En cas de non-réception de vos identifiants veuillez prendre contact auprès de votre interlocuteur European Homes
                                                </p>
                                            </div>
                                        </div>
                                    </v-card>
                                </a>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="12" lg="6">
                        <v-row>
                            <v-col cols="12">
                                <v-card class="pa-2" elevation="8">
                                    <v-card-title class="subtitle-1 pa-0">
                                        <v-icon color="app-blue" class="mr-2"> mdi-file-document-multiple-outline </v-icon>
                                        <span class="grey--text text--darken-2"> Documents </span>

                                        <v-spacer />

                                        <v-chip color="app-blue" label dark small>
                                            {{ availableDocuments.length }}
                                        </v-chip>
                                    </v-card-title>

                                    <v-card-text class="pb-0">
                                        <v-list class="pb-0" dense>
                                            <v-list-item v-for="document of availableDocuments.slice(0, 5)" :key="document.id" :href="getDocumentUrl(document.id)" target="_blank">
                                                <v-list-item-avatar class="my-0">
                                                    <v-icon color="red lighten-1"> mdi-file-pdf-box </v-icon>
                                                </v-list-item-avatar>

                                                <v-list-item-content class="py-0">
                                                    <v-list-item-title>
                                                        <template v-if="document.title">
                                                            {{ document.title }}
                                                        </template>
                                                        <template v-else>
                                                            <i> [Document sans nom] </i>
                                                        </template>
                                                    </v-list-item-title>
                                                </v-list-item-content>
                                            </v-list-item>
                                        </v-list>
                                    </v-card-text>

                                    <v-card-actions class="py-0">
                                        <v-spacer />
                                        <router-link class="caption no-text-decoration primary--text" to="/documents"> Voir tous mes documents </router-link>
                                    </v-card-actions>
                                </v-card>
                            </v-col>

                            <v-col cols="12">
                                <v-card class="pa-2" elevation="8">
                                    <v-card-title class="subtitle-1 pa-0">
                                        <v-icon color="app-blue" class="mr-2"> mdi-newspaper-variant-multiple </v-icon>
                                        <span class="grey--text text--darken-2"> Actualités </span>
                                    </v-card-title>

                                    <v-window v-model="actualitiesPage">
                                        <v-window-item v-for="actuality of actualities" :key="actuality.id">
                                            <ActualityCard :initActuality="actuality" imageHeight="150px" />
                                        </v-window-item>
                                    </v-window>

                                    <v-card-actions v-if="actualities.length > 0" class="justify-space-between flex-grow-1 align-end">
                                        <v-btn icon @click="prev()">
                                            <v-icon> fas fa-angle-left </v-icon>
                                        </v-btn>

                                        <v-item-group v-model="actualitiesPage" class="text-center" mandatory>
                                            <v-item v-for="n in actualities" :key="n.keyid" v-slot="{ active, toggle }">
                                                <v-btn :input-value="active" icon @click="toggle">
                                                    <v-icon small> fas fa-circle </v-icon>
                                                </v-btn>
                                            </v-item>
                                        </v-item-group>

                                        <v-btn icon @click="next()">
                                            <v-icon> fas fa-angle-right </v-icon>
                                        </v-btn>
                                    </v-card-actions>
                                    <v-card-actions v-else>
                                        <h2 class="text-center grey--text"> Aucune actualité </h2>
                                    </v-card-actions>
                                </v-card>
                            </v-col>
                        </v-row>
                    </v-col>
                </v-row>
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import salesMixin from '../mixins/sales';
import documentsMixin from '../mixins/documents';
import callForFundsMixin from '../mixins/callForFunds';

import ProgramSummary from '../components/program/ProgramSummary.vue';
import StepsTimeline from '../components/dashboard/StepsTimeline.vue';
import ActualityCard from '../components/actualities/ActualityCard.vue';

export default {
    name: 'Dashboard',

    mixins: [salesMixin, documentsMixin, callForFundsMixin],

    components: {
        ActualityCard,
        StepsTimeline,
        ProgramSummary
    },

    data: () => ({
        actualitiesPage: 0,
        displayPercents: false
    }),

    methods: {
        async registerRealiz3Click() {
            await this.repos.realiz3d.registerClick({ url: this.selectedSale.realiz_3d_url });
        },

        prev() {
            this.actualitiesPage = this.actualitiesPage === 0 ? this.$store.state.application.actualities.length - 1 : this.actualitiesPage - 1;
        },

        next() {
            this.actualitiesPage = this.actualitiesPage === this.$store.state.application.actualities.length - 1 ? 0 : this.actualitiesPage + 1;
        },

        async reload() {
            try {
                this.setLoading(true);
                await this.$store.dispatch('application/reload');
            } catch (err) {
                console.error(err);
                this.$notify({
                    title: 'Erreur',
                    text: 'Une erreur est survenue lors du rafraichissement de vos données',
                    type: 'error'
                });
            } finally {
                this.setLoading(false);
            }
        }
    },

    computed: {
        realiz3DUrl() {
            const apiUrl = this.$store.state.application.apiRootUrl;
            return `${apiUrl}/realiz3d/${this.selectedSale.id}/${this.selectedSale.product.id}/redirect`;
        },

        areSalesFromSameProgram() {
            const sales = this.$store.state.application.sales;
            const programId = sales[0].program.id;
            return sales.every((s) => s.program.id === programId);
        },

        availableDocuments() {
            const documents = this.selectedSale.documents.filter((d) => d.id !== '0');

            documents.sort((d) => {
                if (d.code === 'CONTRACT') {
                    return -1;
                } else {
                    return 1;
                }
            });

            return documents;
        },

        actualities() {
            return this.$store.state.application.actualities.filter((a) => {
                return a.context === 'buyers'
                    || a.context === 'buyers-contacts'
                    || (a.context === 'buyers-programs' && a.targetCodes.includes(this.selectedSale.program.id))
                    || (a.context === 'buyers-programs-groups' && a.groupProgramIds.includes(this.selectedSale.program.id));
            });
        }
    },

    created() {
        this.$nextTick(() => {
            setTimeout(() => {
                this.displayPercents = true;
            }, 200);
        });
    }
};
</script>
